import React, { useEffect, useState } from 'react'
import { Link, graphql } from "gatsby";
import { connect } from 'react-redux'
import { trackingGA } from '@merchx-v2/helpers'
import { useSiteMetadata } from 'hooks/use-site-metadata';
import { navigate } from 'utils/navigate'
import { Button, Footer, Header, Input, Modal, Notification, SEO } from 'components'
import bagIcon from 'assets/bagIcon.svg'

import { actionCreators as actionHomeCreators, selectMenu } from 'state/HomePage/reducer'
import * as actions from 'state/LoginPage/actions'
import { isPasswordValid, isEmailValid } from 'state/LoginPage/actions'
import { selectors, actionCreators } from 'state/LoginPage/reducer'
import * as styles from './LoginPage.module.scss'

const LoginPage = props => {
  const {
    location,
    loginState,
    menu,

    customerAccountLogin,
    requestResetPassword,
    resetMenuFilters,
    setMenuCollection,
    cleanError,
    data,
  } = props

  const { landing } = useSiteMetadata()

  const [isShowErrors, setIsShowErrors] = useState(false)
  const [notification, showNotification] = useState()
  const [isResetPassword, setIsResetPassword] = useState(false)
  const [isRequestedSuccessfully, setIsRequestedSuccessfully] = useState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isRememberMe, setIsRememberMe] = useState(true)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get('status') === 'resetSuccessful') {
      showNotification('You are reset password successfully!')
    }
    trackingGA()

    return () => {
      cleanError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    showNotification(loginState.UIState.error)
  }, [loginState.UIState.error])

  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCollectionClicked = collection => {
    setMenuCollection(collection)
  }

  const handleReset = () => {
    resetMenuFilters()
  }

  const handleRequestResetPassword = async e => {
    e.preventDefault()
    setIsShowErrors(true)
    if (isEmailValid(email).isValid) {
      const { payload: isRequestedResetPassword } = await requestResetPassword({
        landingId: landing.LANDING_ID,
        email
      })

      if (isRequestedResetPassword === undefined || isRequestedResetPassword === false) {
        setIsRequestedSuccessfully(false)
      } else {
        setIsRequestedSuccessfully(true)
      }
    }
  }

  const handleLogin = async e => {
    e.preventDefault()
    setIsShowErrors(true)
    if (isEmailValid(email).isValid && isPasswordValid(password).isValid) {
      const { payload: isAuthorized } = await customerAccountLogin({
        campaignId: landing.CAMPAIGN.id,
        email,
        password,
        isRememberMe
      })
      if (isAuthorized) {
        navigate(loginState.pageAfterLogin)
      }
    }
  }

  const { SEO_TITLE, SEO_DESCRIPTION, SEO_KEYWORDS } = data?.markdownRemark?.frontmatter || {}

  return (
    <div className={styles.container}>
      <SEO
        title={SEO_TITLE}
        description={SEO_DESCRIPTION}
        keywords={SEO_KEYWORDS}
      />
      <div className={styles.content}>
        <Header menu={menu} onCollectionClicked={handleCollectionClicked} onResetCollection={handleReset} />

        <form className={styles.form} onSubmit={handleLogin}>
          <div className={styles.subtitleContainer}>
            <img className={styles.subtitleIcon} src={bagIcon} alt='' />
            <p className={styles.subtitleText}>Login to purchase or create a free account today</p>
          </div>
          <div className={styles.title}>Log In</div>
          <div className={styles.inputContainer}>
            <Input
              placeholder='Email Address'
              onChange={setEmail}
              isValid={isEmailValid(email).isValid}
              isShowErrors={isShowErrors}
              errorMessage={isEmailValid(email).error}
              name='emailC'
              autoComplete='email'
              bigInput
            />
          </div>
          <div className={styles.inputContainer}>
            <Input
              placeholder='Password'
              onChange={setPassword}
              isValid={isPasswordValid(password).isValid}
              isShowErrors={isShowErrors}
              errorMessage={isPasswordValid(password).error}
              bigInput
              inputType='password'
            />
          </div>

          <div className={styles.forgotBox}>
            <div className={styles.checkboxContainer}>
              <input
                className={styles.checkbox}
                id='checkbox'
                type='checkbox'
                name='check'
                value='check1'
                onClick={() => setIsRememberMe(!isRememberMe)}
                defaultChecked={isRememberMe}
              />
              <label className={styles.checkboxLabel} htmlFor='checkbox'>
                Remember Me
              </label>
            </div>

            <p className={styles.forgotPassword} onClick={() => setIsResetPassword(!isResetPassword)}>
              Forgot Password &gt;
            </p>
          </div>

          <div className={styles.button}>
            <Button value='Log In' loading={loginState.UIState.isLoading} className='buttonMedium' customAction={handleLogin} />
          </div>

          <div className={styles.secondTitle}>Create an Account</div>
          <p className={styles.description}>Create an account with Fan Made Fits to start shopping. Free forever.</p>

          <div className={styles.button} onClick={() => navigate('/register')}>
            <Button value='Create an Account' className='buttonBlue' />
          </div>
        </form>
      </div>

      {isResetPassword && (
        <Modal onClose={() => setIsResetPassword(false)}>
          <div className={styles.modalContainer}>
            <p className={styles.resetTitle}>Reset Your Password</p>
            <p className={styles.description}>Please enter the email used for your account.</p>
            <div className={styles.inputContainer}>
              <Input
                placeholder='Email Address*'
                onChange={setEmail}
                isValid={isEmailValid(email).isValid}
                isShowErrors={isShowErrors}
                errorMessage={isEmailValid(email).error}
                name='emailC'
                autoComplete='email'
              />
              {isRequestedSuccessfully === true && <p className={styles.messageSuccess}>We have e-mailed your password reset link!</p>}
              {isRequestedSuccessfully === false && <p className={styles.messageError}>Email not found. Please try again.</p>}
            </div>
            <div className={styles.button}>
              <Button value='Reset Password' loading={loginState.UIState.isResettingPassword} className='buttonMedium' customAction={handleRequestResetPassword} />
            </div>
            <div className={styles.linkBox}>
              <Link to='/login' className={styles.link} onClick={() => setIsResetPassword(false)}>
                Log In /
              </Link>
              <Link to='/register' className={styles.link}>
                Create an account
              </Link>
            </div>
          </div>
        </Modal>
      )}

      <Notification message={notification} />
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  loginState: selectors.selectLoginState(state),
  menu: selectMenu(state)
})

const mapDispatchToProps = {
  customerAccountLogin: actions.customerAccountLogin,
  requestResetPassword: actions.requestResetPassword,

  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
  cleanError: actionCreators.cleanError
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)

export const query = graphql`
  query LoginPage {
    markdownRemark(fields: {slug: {eq: "/login/"}}) {
      frontmatter {
        SEO_TITLE
        SEO_DESCRIPTION
        SEO_KEYWORDS
      }
    }
  }
`
