// extracted by mini-css-extract-plugin
export var container = "LoginPage-module--container--abjrF";
export var content = "LoginPage-module--content--1eQra";
export var subtitleContainer = "LoginPage-module--subtitleContainer--C8oqr";
export var subtitleIcon = "LoginPage-module--subtitleIcon--1YkZq";
export var subtitleText = "LoginPage-module--subtitleText--kquiq";
export var form = "LoginPage-module--form--1c-mZ";
export var title = "LoginPage-module--title--POEF9";
export var secondTitle = "LoginPage-module--secondTitle--1Mw_w";
export var resetTitle = "LoginPage-module--resetTitle--CmUi5";
export var inputContainer = "LoginPage-module--inputContainer--3bgi-";
export var forgotPassword = "LoginPage-module--forgotPassword--2fudY";
export var description = "LoginPage-module--description--3VVaf";
export var modalContainer = "LoginPage-module--modalContainer--2EtRe";
export var messageSuccess = "LoginPage-module--messageSuccess--3tK3J";
export var messageError = "LoginPage-module--messageError--19eKw";
export var linkBox = "LoginPage-module--linkBox--2cLEq";
export var link = "LoginPage-module--link--SKA8W";
export var forgotBox = "LoginPage-module--forgotBox--2V4mc";
export var checkboxContainer = "LoginPage-module--checkboxContainer--2P8ch";
export var checkbox = "LoginPage-module--checkbox--37ZVw";
export var checkboxLabel = "LoginPage-module--checkboxLabel--26Zkq";
export var button = "LoginPage-module--button--2XJhq";
export var footerContainer = "LoginPage-module--footerContainer--qUcqB";